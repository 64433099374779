import router from './route/index'
import store from './store/index'
import VueCookies from 'vue-cookies'



router.beforeEach(async (to, from, next) => {
    const hasToken = VueCookies.get('accessToken') || '';    //  token
    if (hasToken) {
        if (to.path === '/login') {
            // 在有token访问登录页面，直接去首页
            next({ path: '/' })
        } else {
            //hasRoutes 是否已经获取到了后端返回的路由
            const hasRoutes = store.getters.addRoutes && store.getters.addRoutes.length > 0
            if (hasRoutes) {
                link(to.path, next)
            } else {
                let addRoutes = await store.dispatch('permission/generateRoutes')
                if (addRoutes.length == 0) {
                    next({ path: '/login' })
                }else{
                    router.addRoute(addRoutes[0])
                    router.addRoute({ path: '*', redirect: '/404', hidden: true })
                    next({ path: to.path })
                }
            }
        }
    } else {
        if (to.path == '/login') {
            next()
        } else {
            next({ path: '/login' })
        }
    }
})
let crouter = ''
function link (path, next) {//传入一个path跳转到path的子路由的最底层
    let addRoutes = store.getters.addRoutes
    crouter = ''
    GetRouter(path, addRoutes)
    if (crouter && crouter.children && crouter.children.length) {
        next({ path: Arriteration(crouter) });
        next();
        return
    }
    next()
}
function Arriteration (router) {//传入一个router返回最底层路由的path
    if (router.children && router.children.length) {
        return Arriteration(router.children[0])
    } else {
        return router.path
    }
}
function GetRouter (path, routers) {//传入一个path对应的路由对象
    if (routers.some(r => r.path == path)) {
        crouter = routers.filter(r => r.path == path)[0]
    }
    else {
        for (let i = 0; i < routers.length; i++) {
            if (routers[i].children && routers[i].children.length) {
                GetRouter(path, routers[i].children)
            }
        }
    }
}