import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en'
import zhHans from './zh-Hans'
Vue.use(VueI18n)
//创建i18n对象
const i18n = new VueI18n({
  locale: navigator.language || navigator.browserLanguage, // 获取已设置的语言
  fallbackLocale: 'zh',
  messages: {
    en,
    zh: zhHans,
  },
})

export default i18n
