



import request from "../utility/http"

/**
 * 请求 项目及付款计划的数据
 * @param {*} pmClass 
 * @param {*} role 
 * @returns 返回 项目及付款计划的数据
 */
export function PMProjectGetList (pmClass, role) {
    return request.get(`/api/PMProject/GetList?pmClass=${pmClass}&role=${role}`)
}


/**
 * 请求某个项目的信息
 * @param {*} id 
 * @param {*} bcId 
 * @returns 返回项目信息
 */

export function PMProjectGetId (id, bcId) {
    return request.get(`/api/PMProject/Get/${id}` + (bcId ? `?bcId=${bcId}` : ''))
}

/**
 * 新增项目
 * @param {Object} data 传入新增的项目信息
 * @returns 返回是否新增成功
 */
export function PMProjectPostAdd (data) {
    return request.post('/api/PMProject/Add', data)
}

/**
 * 更新项目信息
 * @param {Object} data 传入项目更新后的信息
 * @returns 返回是否更新成功
 */
export function PMProjectPostUpdate (data) {
    return request.post('/api/PMProject/Update', data)
}

/**
 * 删除一个项目
 * @param {*} id 
 * @returns 是否成功
 */
export function deletePMProject (id) {
    return request.delete('/api/PMProject/Delete/' + id + '')
}


/**
 * 项目负责人修改信息
 * @param {Object} data 传入项目更新后的信息
 * @returns 返回是否更新成功
 */

export function PMManagerUpdate (data) {
    return request.post('/api/PMProject/PMManagerUpdate', data)
}
/**
 * 获取项目成本控制信息
 * @param {*} id 
 * @returns 返回信息数据
 */

export function GetProjectCost (id) {
    return request.get(`/api/PMProject/GetProjectCost/${id}`,)
}
/**
 * 修改项目信息
 * @param {*} data 
 * @returns 是否修改成功
 */
export function UpdateProjectCost (data) {
    return request.post(`/api/PMProject/UpdateProjectCost`, data)
}
/**
 * 获取项目成本预算表
 * @param {*} id 
 * @returns 返回项目列表
 */
export function GetPMProjectSoftBudget (id) {
    return request.get(`/api/PMProject/GetPMProjectSoftBudget/${id}`,)
}
/**
 * 修改项目成本预算表
 * @param {*} id 
 * @param {*} data 
 * @returns 是否成功
 */
export function UpdatePMProjectSoftBudget (id, data) {
    return request.post(`/api/PMProject/UpdatePMProjectSoftBudget/${id}`, data)
}

/**
 * 获取项目成本数据
 * @param {*} id 
 * @returns //返回数据列表
 */
export function GetPMProjectCostInfo (id) {
    return request.get(`/api/PMProject/GetPMProjectCostInfo/${id}`,)
}

/**
 * 获取某个科目的明细表
 * @param {*} id 
 * @param {*} account 
 * @returns 
 */
export function GetPMProjectCostInfoOneAccountList (id, account) {
    return request.get(`/api/PMProject/GetPMProjectCostInfoOneAccountList/${id}/${account}`,)
}
/**
 * 地产排名
 * @param {*} signDate1 
 * @param {*} signDate2 
 * @returns 
 */

export function GetPMProjectDiChanSortList (signDate1, signDate2) {
    return request.get(`/api/PMProject/GetPMProjectDiChanSortList?signDate1=${signDate1}&signDate2=${signDate2}`,)
}

/**
 * 按比例计算付款节点金额
 * @param {*} id 
 * @returns 
 */
export function ComputeNodeMoney (id) {
    return request.post(`/api/PMProject/ComputeNodeMoney`, id, { headers: { "Content-type": "application/json" } })
  }
  