import request from "../utility/http"
/**
 * 请求部门管理的组织架构
 * @param {*} data 
 * @returns 返回部门组织架构树状图
 */
export function sysdeptGetList (data) {
    return request.get('/api/SysDept/getList', data)
}

/**
 *  请求某个部门的部门信息
 * @param {Number} id 传入部门id
 * @returns 返回部门信息
 */

export function sysdeptGetId (id) {
    return request.get(`/api/SysDept/Get/${id}`)
}

/**
 * 新增部门
 * @param {Object} data 传入新增的部门信息
 * @returns 返回是否新增成功
 */
export function sysdeptPostAdd (data) {
    return request.post('/api/SysDept/Add', data)
}

/**
 * 更新部门信息
 * @param {Object} data 传入部门更新后的信息
 * @returns 返回是否更新成功
 */

export function sysdeptPostUpdate (data) {
    return request.post('/api/SysDept/Update', data)
}

/**
 * 
 * @param {*} data 
 * @returns 返回公司待选名单
 */
export function sysUserGetListNoDept (data) {
    return request.get('/api/SysUser/GetListNoDept', data)
}

/**
 * 
 * @param {*} data 
 * @returns 返回公司待选名单
 */
export function sysUserDeleteDept (id) {
    return request.delete(`/api/SysDept/Delete/${id}`)
}

export function GetDeptInfoList () {
    return request.get(`/api/SysDept/GetDeptInfoList`)
}
/**
 * 部门拖拽
 * @param {*} data 
 * @returns 
 */

export function Drag (dragId, dropId) {
    return request.post(`/api/SysDept/Drag/${dragId}/${dropId}`)
}