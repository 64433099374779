import request from "../utility/http"


export function GetByRelationGuid (guid) {
    return request.get(`/api/SysAttachment/GetByRelationGuid/${guid}`)
}



/**
 * 上传文件
 * @param {*} guid goid
 * @param {*} file 文件名
 * @returns 上传内容
 */
export function Upload (guid, file) {
    let forms = new FormData();
    forms.append("file", file);
    forms.append("relationGuid", guid);
    return request.post(`/api/SysAttachment/Upload`, forms, { headers: { "Content-type": "application/x-www-form-urlencoded" } })
}

/**
 * 删除上传的文件
 * @param {传入一个对象包含要上传的文件 relationGuid 这个字段是合同的attachmentGuid  file 文件} 
 * @returns 返回删除成功或者失败
 */
export function Delete (guid) {
    return request.delete(`/api/SysAttachment/Delete/${guid}`)
}

/**
 * 获取oss的服务端签名
 * @param {*} callbackUrl 
 * @returns 
 */
export function GetOSSPolicyToken (callbackUrl) {
    return request.post(`/api/SysAttachment/GetOSSPolicyToken`, `"${callbackUrl}"`, { headers: { "Content-type": "application/json" } })
}

/**
 * 获取当前文件存储类型
 * @returns 
 */
export function GetFileStorageType () {
    return request.get(`/api/SysAttachment/GetFileStorageType`)
}

/**
 * 将文件直接上传到oss
 * @param {*} ossUrl 
 * @param {*} data 
 * @returns 
 */
export function UploadToOSS (ossUrl, data) {
    let forms = new FormData();
    for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
            forms.append(key, data[key]);
        }
    }
    return request.post(ossUrl, forms, { headers: { "Content-type": "application/x-www-form-urlencoded" } })
}