<template>
  <div id="app">
      <router-view />
  </div>
</template>

<script>
import "@/styles/overrides.sass";
import { mapGetters } from "vuex";

export default {
  name: "App",
  created () {
    if (this.proportion !== devicePixelRatio) {
      this.$store.commit("app/setProportion", devicePixelRatio);
      let barHeight = this.appBarshow ? 116 : 68;
      this.$store.commit("app/setViewHeight", window.innerHeight - barHeight);
    }
    window.onresize = () => {
      this.$store.commit("app/setProportion", devicePixelRatio);
      let barHeight = this.appBarshow ? 116 : 68;
      this.$store.commit("app/setViewHeight", window.innerHeight - barHeight);
    };
  },
  mounted () {

  },
  computed: {
    ...mapGetters(["proportion", "appBarshow"]),
  },
};
</script>

<style lang="scss">
@import "./styles/app.scss";
</style>
