import request from "../utility/http"

export function GetList() {
    return request.get('/api/FlowTemp/GetList')
}

export function Get(id) {
    return request.get('/api/FlowTemp/Get/' + id)
}

export function Add(data) {
    return request.post('/api/FlowTemp/Add', data)
}

export function Update(data) {
    return request.post('/api/FlowTemp/Update', data)
}

export function Delete(id) {
    return request.delete(`/api/FlowTemp/Delete/${id}`)
}

export function UpdateDesign(data) {
    return request.post('/api/FlowTemp/UpdateDesign', data)
}

export function GetFlowTempNodeByGuid(guid) {
    return request.get('/api/FlowTemp/GetFlowTempNodeByGuid/' + guid)
}

export function UpdateFlowTempNode(data) {
    return request.post('/api/FlowTemp/UpdateFlowTempNode', data)
}

export function GetFlowTempLinkByGuid(guid) {
    return request.get('/api/FlowTemp/GetFlowTempLinkByGuid/' + guid)
}

export function UpdateFlowTempLink(data) {
    return request.post('/api/FlowTemp/UpdateFlowTempLink', data)
}

export function ReleaseVer(id) {
    return request.post('/api/FlowTemp/ReleaseVer', id, { headers: { "Content-type": "application/json" } })
}

export function CreateNewVer(id) {
    return request.post('/api/FlowTemp/CreateNewVer', id, { headers: { "Content-type": "application/json" } })
}