
import request from "../utility/http"


/**
 * 请求合同节点信息选项
 * @param {传入列表id} id 
 * @returns 返回合同节点信息选项
 */
export function BMContractinPaymentNodeGet (id, pmId) {
    return request.get(`/api/BMContractinPaymentNode/Get/${id}` + (pmId ? `?pmId=${pmId}` : ''))
}

/**
 * 新增合同节点信息
 * @param {传入新增的列表内容} data 
 * @returns 返回增加成功或者失败
 */
export function BMContractinPaymentNodePostAdd (data) {
    return request.post("/api/BMContractinPaymentNode/Add", data)
}

/**
 * 更新合同节点信息
 * @param {传入要更新的列表内容} data 
 * @returns 返回更新成功或者失败
 */
export function BMContractinPaymentNodePostUpdate (data) {
    return request.post("/api/BMContractinPaymentNode/Update", data)
}

/**
 * 删除合同节点信息的某个
 * @param {传入删除某个列表的id} id 
 * @returns 返回删除成功或者失败
 */
export function BMContractinPaymentNodeDelete (id) {
    return request.delete(`/api/BMContractinPaymentNode/Delete/${id}`)
}


export function UpdatePlan (data) {
    return request.post("/api/BMContractinPaymentNode/UpdatePlan", data)
}


