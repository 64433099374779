
import request from "../utility/http"

/**
 * 请求我的设计合同列表
 * @returns 返回参数维护列表
 */
export function BMContractinGetList (role, isVirtual, businessStatus) {
    return request.get(`/api/BMContractin/GetList?role=${role}&isVirtual=${isVirtual}&businessStatus=${businessStatus}`)
}

/**
 * 请求设计合同列表选项
 * @param {传入列表id} id 
 * @returns 返回设计合同列表选项
 */
export function sysCodeGroupGet (id) {
    return request.get(`/api/BMContractin/Get/${id}`)
}

/**
 * 新增设计合同列表
 * @param {传入新增的列表内容} data 
 * @returns 返回增加成功或者失败
 */
export function sysCodeGroupPostAdd (data) {
    return request.post("/api/BMContractin/Add", data)
}

/**
 * 更新设计合同列表
 * @param {传入要更新的列表内容} data 
 * @returns 返回更新成功或者失败
 */
export function sysCodeGroupPostUpdate (data) {
    return request.post("/api/BMContractin/Update", data)
}

/**
 * 删除设计合同列表的某个
 * @param {传入删除某个列表的id} id 
 * @returns 返回删除成功或者失败
 */
export function sysCodeGroupDelete (id) {
    return request.delete(`/api/BMContractin/Delete/${id}`)
}
/**
 * 上传文件
 * @param {传入一个对象包含要上传的文件 relationGuid 这个字段是合同的attachmentGuid  file 文件} 
 * @returns 返回删除成功或者失败
 */
export function SysAttachmentUpload (data) {
    return request.post(`/api/SysAttachment/Upload`, data, { headers: { "Content-type": "application/x-www-form-urlencoded" } })
}

/**
 * 删除上传的文件
 * @param {传入一个对象包含要上传的文件 relationGuid 这个字段是合同的attachmentGuid  file 文件} 
 * @returns 返回删除成功或者失败
 */
export function SysAttachmentDelete (guid) {
    return request.delete(`/api/SysAttachment/Delete/${guid}`)
}