const addListener = function(el, type, fn) {
  el.addEventListener(type, fn, false);
};
const spaceFilter = function(el) {
  addListener(el, "keyup", () => {
    el.value = el.value.replace(/s+/, "");
  });
};
const intFilter = function(el) {
  addListener(el, "keyup", () => {
    el.value = el.value.replace(/D/g, "");
  });
};
const priceFilter = function(el) {
  addListener(el, "keyup", () => {
    el.value = el.value.replace(/[^d.]*/g, "");
    if (isNaN(el.value)) {
      el.value = "";
    }
  });
};
// const specialFilter = function(el) {
//   addListener(el, "keyup", () => {
//     el.value = el.value.replace(/[^a-zA-0-9u4E00-u9FA5]/g, "");
//   });
// };
const phoneFilter = function(el) {
  addListener(el, "blur", () => {
    if (!el.value) {
      return;
    }
    const phoneReg = new RegExp("^(13|14|15|16|17|18|19)[0-9]{9}$");
    if (!phoneReg.test(el.value)) {
      alert("手机号输入错误");
      el.value = "";
    }
  });
};
const ArraytoString = function(el) {
    addListener(el, "input", () => {
        console.log(el);
        console.log(el.value);
      if (!el.value) {
        return;
      }
      el.value.reduce((pre,cur,index)=>{
          if(index==el.value.length) return cur
        return pre+','+cur
      })
      return el.value
    });
  };
//   const urlFilter = function(el) {
//     addListener(el, 'blur', () => {
//       if (!el.value) {
//         return
//       }
//       const urlReg = /(^#)|(^http(s*)://[^s]+.[^s]+)/
//       if (!urlReg.test(el.value)) {
//         alert('url输入错误')
//         el.value = ''
//       }
//     })
//   }

function inputFilter(el, binding) {
    if (el.tagName.toLowerCase() !== "input") {
      el = el.getElementsByTagName("input")[0];
    }
    spaceFilter(el);
    switch (binding.arg) {
      case "int":
        intFilter(el);
        break;
      case 'ArraytoString':
        ArraytoString(el);
        break;
      case "price":
        priceFilter(el);
        break;
    //   case "special":
    //     specialFilter(el);
    //     break;
      case "phone":
        phoneFilter(el);
        break;
      case "url":
        urlFilter(el);
        break;
      default:
        break;
    }
  }
// const install = function(Vue) {
//   Vue.directive('inputFilter', inputFilter)
// }

// if (window.Vue) {
//   window.inputFilter = inputFilter
//   Vue.use(install)
// }

// inputFilter.install = install
export default inputFilter