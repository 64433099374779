import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'element-ui/lib/theme-chalk/index.css'
import Vue from 'vue'
import request from '../src/utility/http'
import App from './App.vue'
import element from './components/element/index'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
Vue.use(element)
// import message from './components/message/message.js'
// Vue.use(message)

import * as math from 'mathjs'
Vue.prototype.$math = math.create(math.all, {
  epsilon: 1e-12,
  matrix: 'Matrix',
  number: 'BigNumber',
  precision: 64,
  predictable: false,
  randomSeed: null,
})
Vue.prototype.$echarts = echarts

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

import * as echarts from 'echarts'
import 'print-js'
import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import SuperFlow from 'vue-super-flow'
import 'vue-super-flow/lib/index.css'
import * as filters from './filters' // global filters
import inputFilter from './filters/inputFilter'
import './permission' // permission control
import './plugins/app'
import router from './route/index'
import store from './store'
Vue.prototype.$Swiper = VueAwesomeSwiper
Vue.use(VueAwesomeSwiper)

Vue.component('apexchart', VueApexCharts)
Vue.use(SuperFlow)
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
Vue.directive('inputFilter', inputFilter)
Vue.config.productionTip = false
Vue.prototype.$ = request

// Vue.prototype.$requiredRules = [(v) => (v !== undefined && v !== null && v !== '') || '这是必填字段']
// Vue.prototype.$dateRules = [(v) => new RegExp(/^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d{1}|3[01])$/).test(v) || '日期格式不对  YYYY-MM-DD']

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

import globalMethods from './plugins/globalMethods'
Vue.use(globalMethods)

let vue = new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
export default vue
